import { TCardData } from '~/types/common'

import { getDatabase, ref, set, push, onValue } from "firebase/database";

export default {
  state: () => ({
    cardData: {} as TCardData | null,
    cardKey: '' as string,
    loadingCardData: true as boolean,
  }),
  mutations: {
    SET_CARD_DATA (state: any, payload: any) {
      // console.log('setting card data')
      state.cardData = payload
    },
    SET_CARD_KEY (state: any, payload: any) {
      // console.log('setting card data')
      state.cardKey = payload
    },
    SET_CARD_LOADING_STATE (state: any, payload: any) {
      // console.log('setting card data')
      state.loadingCardData = payload
    },
  },
  actions: {
    async getCardData (  
      { commit, rootState }: { commit: any, rootState: any}, 
    ) {
      // console.log('dispatch getting card data')
      const db = getDatabase();
      const userRef = ref(db, `users/${rootState.userStore.user?.uid}/cardKey`);

      try {
        await onValue(userRef, (snapshot) => {
          const cardKey = snapshot.val();
          if (!cardKey) {
            commit('SET_CARD_DATA', {})
            commit('SET_CARD_LOADING_STATE', false);
            return console.log('no data available to get')
          }

          commit('SET_CARD_KEY', cardKey)

          onValue(ref(db, `cards/${cardKey}`), (snapshot) => {
            const cardData = snapshot.val();
            commit('SET_CARD_DATA', cardData.fields)
            commit('SET_CARD_LOADING_STATE', false);
          });
        });

      } catch (err: any) {
        // commit('SET_CARD_LOADING_STATE', false);
        console.error(err) 
      }
    },
    async setCardData (
      { commit, rootState }: { commit: any, rootState: any}, 
      userDataFields: any
    ) {
      // console.log('dispatch setting card data')
      const db = getDatabase();
      const userID = rootState.userStore.user?.uid;

      try {
        // TODO: if .uid if anything else (i.e. .asdfuid) data gets stored on the root of the document 
        const userCardRef = ref(db, 'users/' + userID + '/cardKey' );
        let cardKey;

        await onValue(userCardRef, (snapshot) => {
          const data = snapshot.val();

          if (data) {
            cardKey = data;
          } else {
            cardKey = push(ref(db, 'cards')).key;
            set(userCardRef, cardKey);
          }
        });

        const cardRef = ref(db, '/cards/' + cardKey);
        const newCardContent = {
          creator: userID,
          fields: userDataFields
        }

        await set(cardRef, newCardContent);

      } catch (err: any) {
        console.error(err);
      }
    },
  },
  getters: {
    getCardData: (state: any) => state.cardData,
    getCardKey: (state: any) => state.cardKey,
    getCardIsFilledState: (state: any) => state.cardData && Object.keys(state.cardData).length,
  }
}
