import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "toaster-wrapper"
}
const _hoisted_2 = { class: "system-message__icon material-icons is-md" }
const _hoisted_3 = { class: "system-message__title title is-6" }
const _hoisted_4 = { class: "system-message__body" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.sysMsgs.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_TransitionGroup, {
          name: "toaster",
          tag: "ul",
          class: "toaster"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sysMsgs, (msg) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "system-message",
                key: msg.key
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(msg.action ? 'button' : 'div'), { class: "system-message__button" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(msg.icon), 1),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(msg.title), 1),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(msg.body), 1)
                  ]),
                  _: 2
                }, 1024)),
                _createElementVNode("button", {
                  class: "system-message__close material-icons",
                  onClick: ($event: any) => (_ctx.close(msg.key))
                }, "close", 8, _hoisted_5)
              ]))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}