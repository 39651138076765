
import { defineComponent } from 'vue';
import Auth from '@/components/Auth.vue';

export default defineComponent({
  name: 'Login',
  components: {
    Auth,
  },
  // setup() {
  //   return {
  //   }
  // }
})
