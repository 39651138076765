
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Modal',
  // emits: [''],
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
    },
    message: {
      type: String as PropType<string>,
      required: true
    },
    callToActionText: {
      type: String as PropType<string>,
      default: 'Confirm',
      required: false
    },
    secondaryButtonText: {
      type: String as PropType<string>,
      default: 'Cancel',
      required: false
    },
  }
})
