
import { defineComponent, ref, watch, PropType } from 'vue'

export default defineComponent({
  name: 'CustomButton',
  props: {
    loadingState: {
      type: Boolean as PropType<boolean>,
      required: false
    },
    errorMessage: {
      type: String as PropType<string>,
      required: false
    }
  },
  setup(props) {
    const buttonRef = ref<HTMLButtonElement | null>(null);
    const buttonErrorClassTrigger = ref(false);
    
    watch(() => props.errorMessage, (newVal, oldVal) => {
      const buttonElRef = buttonRef.value;
      if (!buttonElRef || newVal === '' || newVal === oldVal) return
      buttonErrorClassTrigger.value = true;
      buttonElRef?.addEventListener('animationend', () => {
        buttonErrorClassTrigger.value = false;
      });
    })

    return {
      buttonRef,
      buttonErrorClassTrigger
    }
  }
})
