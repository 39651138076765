
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex';
import { TSystemMessage } from '~/types/common'

export default defineComponent({
  setup(_) {
    const store = useStore();
    const close = (msgKey: TSystemMessage['key']) => {
      store.commit('REMOVE_SYSTEM_MESSAGE', msgKey)
    }
    return {
      sysMsgs: computed<TSystemMessage[]>(() => store.state.shellStore.systemMessages),
      close
    }
  },
})
