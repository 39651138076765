import { TSystemMessage } from '~/types/common'
import { BeforeInstallPromptEvent } from '~/types/Events'

export default {
  state: () => ({
    appVersion: process.env.VUE_APP_VERSION as string,
    // EXPERIMENTAL FEATURE
    deferredPrompt: null as BeforeInstallPromptEvent | null,
    initialLoadState: false as boolean,
    modalVisibility: false as boolean,
    promptedForInstallation: false as boolean,
    systemMessages: [] as TSystemMessage[],
  }),
  mutations: {
    // EXPERIMENTAL FEATURE
    ADD_SYSTEM_MESSAGE (state: any, payload: TSystemMessage) {
      const stateCopy = [...state.systemMessages];
      payload.key = new Date().getTime();
      stateCopy.unshift(payload);
      state.systemMessages = stateCopy;
    },
    REMOVE_SYSTEM_MESSAGE (state: any, payload: TSystemMessage['key']) {
      const stateCopy = [...state.systemMessages];
      const messageIndex = stateCopy.findIndex(msg => payload === msg.key);
      stateCopy.splice(messageIndex, 1)
      state.systemMessages = stateCopy;
    },
    SET_DEFERRED_PROMPT (state: any, payload: any) {
      console.log('prompt payload: ', payload)
      state.deferredPrompt = payload
    },  
    PROMPT_INSTALLATION (state: any) {
      console.log('prompting for installation')
      state.deferredPrompt?.prompt();
      // const { outcome } = deferredPrompt.userChoice
       state.deferredPrompt?.userChoice
        .then((res: any) => {
          console.log('user choice response: ', res)
        })
        .catch((err: any) => {
          console.log(err)
        });

    },
    SET_INSTALLION_PROMPT_TO_TRUE (state: any) {
      state.promptedForInstallation = true
    },
    SET_MODAL_VISIBILITY (state: any, payload: boolean) {
      // console.log('switching modal')
      state.modalVisibility = payload
    },
    SET_INITIAL_LOAD_STATE (state: any) {
      // console.log('setting initial load state')
      state.initialLoadState = true;
    }
  },
  actions: {
  },
  getters: {
    getAppVersion: (state: any) => state.appVersion
  }
}
