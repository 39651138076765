
import { defineComponent, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';

import Loader from '@/components/Loader.vue'
import Shell from '@/components/Shell.vue'
import Logo from '@/components/Logo.vue'

export default defineComponent({
  components: {
    Loader,
    Logo,
    Shell
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    onBeforeMount(() => {
      window.addEventListener('offline', function(e) {
        store.commit('ADD_SYSTEM_MESSAGE', {
          // icon: 'signal_disconnected',
          icon: 'cell_tower',
          title: 'No connection to the internet!',
        })
      });

      window.addEventListener('online', function(e) {
        store.commit('ADD_SYSTEM_MESSAGE', {
          icon: 'cell_tower',
          title: 'Connected to the internet!',
        })
      });
    })

    return {
      initialLoadingDone: computed(() => store.state.shellStore.initialLoadState),
      routeName: computed(() => String(route.name).toLowerCase() + '-view')
    }
  }
})
