
import { defineComponent, computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Modal from '@/components/Modal.vue'
import Loader from '@/components/Loader.vue'
import Logo from '@/components/Logo.vue'
import Toaster from '@/components/Toaster.vue'
import Settings from '@/components/Settings.vue'

// import { BeforeInstallPromptEvent } from '~/types/Events'

export default defineComponent({
  components: {
    Modal,
    Loader,
    Logo,
    Toaster,
    Settings
  },
  setup() {
    const store = useStore()
    const route = useRoute();

    const errors = ref([''])
    const showInstallPromotion = ref(false)

    const sendMessage = () => {
      store.commit('ADD_SYSTEM_MESSAGE', {
        icon: 'info',
        title: 'New version available!',
        // body: 'Click here to update...'
      })
    }


    let deferredPrompt: any;

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
      showInstallPromotion.value = true;
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });


    // PWA INSTALLATION
    // const promptInstallation = () => {
    //   console.log('installationSetup')
    //   window.addEventListener("beforeinstallprompt", e => {
    //     // e.preventDefault();
    //     // Stash the event so it can be triggered later.
    //     // deferredPrompt.value = e;
    //     // console.log('prompt set for installation')
    //     // console.log(e)
    //     // store.commit('SET_DEFERRED_PROMPT', e)

    //     // Optionally, send analytics event that PWA install promo was shown.
    //     console.log(`'beforeinstallprompt' event was fired.`);
    //   });
    //   window.addEventListener("appinstalled", () => {
    //     // console.log('installed')
    //     // deferredPrompt.value = null;
    //     // store.commit('SET_DEFERRED_PROMPT', null)
    //   });
    // }

    const dismiss = async () => {
      // deferredPrompt.value = null
      store.commit('SET_DEFERRED_PROMPT', null)
      setModalVisiblityTo(false)
    }

    const install = async () => {
  // Hide the app provided install promotion
      // hideInstallPromotion();
      showInstallPromotion.value = false
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt = null;

      // try {
      //   await store.commit('PROMPT_INSTALLATION')
      // }
      // catch (error: any) {
      //   errors.value.push(error)
      // }
      // // await store.state.deferredPrompt.value.userChoice.then((choice: any) => {
      // //   if (choice.outcome === 'accepted') {
      // //     console.log('User accepted the A2HS prompt');
      // //   } else {
      // //     console.log('User dismissed the A2HS prompt');
      // //   }
      //   // Clear the saved prompt since it can't be used again
      //   // deferredPrompt.value = null;
      //   store.commit('SET_DEFERRED_PROMPT', null)
      // // });
      // setModalVisiblityTo(false)
    }

    const setModalVisiblityTo = (state: boolean) => {
      store.commit('SET_MODAL_VISIBILITY', state)
    }

    // promptInstallation()

    return {
      deferredPrompt: computed(() => store.state.shellStore.deferredPrompt),
      dismiss,
      errors,
      install,
      initialLoadingDone: computed(() => store.state.shellStore.initialLoadState),
      showInstallPromotion,
      setModalVisiblityTo,
      sendMessage,
      route,
      isUserLoggedIn: computed(() => store.getters.isUserLoggedIn),
      modalVisibilityState: computed(() => store.state.shellStore.modalVisibility),
    }
  }
})
