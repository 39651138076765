
import { defineComponent, ref, reactive, PropType, onMounted } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { auth } from '../main';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

import CustomButton from '@/components/CustomButton.vue';
import PasswordStrength from '@/components/PasswordStrength.vue';

type TAuthControl = {
  key: 'email' | 'password',
  label: string,
  value: string,
  type: 'email' | 'password' | 'text',
  autocomplete?: 'email' | 'new-password' | 'current-password' | 'one-time-code',
  placeholder: string,
  show?: boolean 
} // extends HTMLInputElement

export default defineComponent({
  name: 'Authentication',
  components: {
    CustomButton,
    PasswordStrength
  },
  props: {
    mode: {
      type: String as PropType<'login' | 'sign-up'>,
      required: true
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const tabs = [{
      key: 'signUp',
      label: 'Sign up'
     }, { 
      key: 'login',
      label: 'Login'
    }]
    const activeTab = ref(tabs[1])
    const submitted = ref(false)
    const showPasswordSuggestions = ref(false)
    const controls: TAuthControl[] = reactive([{
      key: 'email',
      label: 'Email',
      value: '',
      type: 'email',
      autocomplete: 'email',
      placeholder: 'Insert email here...'
    }, {
      key: 'password',
      label: 'Password',
      value: '',
      type: 'password',
      autocomplete: 'current-password',
      placeholder: 'Insert password here...',
      show: false
    }])

    const error = ref('');

    onMounted(() => {
      if (props.mode === 'sign-up')
        activeTab.value = tabs[0]
    })
    
    const submitForm = () => {
      const email = controls.find(control => control.key === 'email')!.value;
      const password = controls.find(control => control.key === 'password')!.value;
      
      submitted.value = true;
      // 1. validate input
      if (!email.length || !password.length) {
        submitted.value = false;
        return error.value = 'Email and password can\'t be empty';
      }
      // 2. check active tab
      // 3. execute appropriate function
      if (activeTab.value.key === 'login') 
        return login(email, password)
      else 
        return signup(email, password)
    }

    const signup = (email: string, password: string) => { 
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential: any) => {
          const user = userCredential.user;
          store.commit('SET_USER_DATA', user);
          router.push('/');
          // console.log('Sign up data: ', userCredential)
        })
        .catch((error: any) => { errorHandling(error) });
    }

    const login = (email: string, password: string) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          store.commit('SET_USER_DATA', user);
          router.push('/');
        })
        .catch((error) => { errorHandling(error) });
    }

    const resetPassword = () => {
      const email = controls.find(control => control.key === 'email')!.value;

      sendPasswordResetEmail(auth, email)
        .then(() => {
          store.commit('ADD_SYSTEM_MESSAGE', {
            icon: 'info',
            title: 'Password reset link sent',
            body: `Check your SPAM folder if you haven't received anything`
            // body: `Click to open mail application...`
          })
          // Password reset email sent!
        })
        .catch((error) => { errorHandling(error) });
    }

    const errorHandling = (firebaseError: any) => {
      error.value = firebaseError.message;
      submitted.value = false;
    }

    return {
      activeTab,
      controls,
      error,
      resetPassword,
      showPasswordSuggestions,
      submitForm,
      submitted,
      tabs,
    }
    
  }
})
