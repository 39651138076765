import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { connectAuthEmulator, onAuthStateChanged, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export let auth: any;
export let database: any;

if (process.env.NODE_ENV === 'development') {
  initializeApp(firebaseConfig);

  auth = getAuth();
  database = getDatabase();

  // self.FIREBASE_APPCHECK_DEBUG_TOKEN = 
  //   process.env.VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN || true;

  connectAuthEmulator(auth, "http://192.168.0.6:9099");
  connectDatabaseEmulator(database, "192.168.0.6", 9000);
}

if (process.env.NODE_ENV === 'production') {
  const firebaseApp = initializeApp(firebaseConfig);
  const perf = getPerformance(firebaseApp);
  const analytics = getAnalytics(firebaseApp);
  
  auth = getAuth(firebaseApp);
  database = getDatabase(firebaseApp);

  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(process.env.VUE_APP_RECAPTCHA),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    // isTokenAutoRefreshEnabled: true
  });
}

createApp(App)
  .use(store)
  .use(router)
  .mount('#app');

if (auth) {
  onAuthStateChanged(auth, user => {
    // Check for user status
    if (user) {
      // User is signed in
      console.log('user signed in')
      userSignedIn(user);
    } else {
      // User is signed out
      console.log('user signed out')
      userSignedOut();
    }
  });
}

const userSignedIn = async (user: any) => {
  try {
    await store.commit('SET_USER_DATA', user);
    await store.dispatch('getCardData');
    // await router.replace({ name: 'Cards' })
    // TODO: Fix view flickering
    await store.commit('SET_INITIAL_LOAD_STATE');
  } catch (err) {
    console.error('error signing in')
    console.error(err)
  }
}

const userSignedOut = async () => {
  try {
    await store.commit('SET_USER_DATA', {});
    await store.commit('SET_INITIAL_LOAD_STATE');
    // await router.replace({ name: 'Login' })
  } catch (err) {
    console.error('error signing out')
    console.error(err)
  }
}