export default {
  state: () => ({
    user: {} as any,
  }),
  mutations: {
    SET_USER_DATA (state: any, payload: any) {
      // console.log('setting user data')
      state.user = payload
    }
  },
  actions: {
  },
  getters: {
    isUserLoggedIn: (state: any) => Object.keys(state.user).length > 0,
  }
}
