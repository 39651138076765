import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "splash-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo = _resolveComponent("logo")!
  const _component_loader = _resolveComponent("loader")!
  const _component_shell = _resolveComponent("shell")!

  return (_openBlock(), _createBlock(_Transition, {
    mode: "out-in",
    name: "fade",
    appear: ""
  }, {
    default: _withCtx(() => [
      (!_ctx.initialLoadingDone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_logo, { class: "mb-4" }),
            _createVNode(_component_loader, {
              class: "splash-screen__loader",
              fillContainer: false
            })
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["application-layer yellow-bg", _ctx.routeName])
          }, [
            _createVNode(_component_shell)
          ], 2))
    ]),
    _: 1
  }))
}