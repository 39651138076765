import { createStore } from 'vuex'

import cardStore from './cardStore';
import shellStore from './shellStore';
import userStore from './userStore';

import { VuexModule } from 'vuex-class-component/dist/interfaces';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: {
    cardStore,
    shellStore,
    userStore,
  }
})
