import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal-card" }
const _hoisted_3 = { class: "modal-card-head" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "modal-card-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "modal-background",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("p", {
          class: "modal-card-title",
          textContent: _toDisplayString(_ctx.title)
        }, null, 8, _hoisted_4),
        _createElementVNode("button", {
          class: "delete",
          "aria-label": "close",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal')))
        })
      ]),
      _createElementVNode("section", {
        class: "modal-card-body",
        textContent: _toDisplayString(_ctx.message)
      }, null, 8, _hoisted_5),
      _createElementVNode("footer", _hoisted_6, [
        _createElementVNode("button", {
          class: "button is-link",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('callToActionClick')))
        }, _toDisplayString(_ctx.callToActionText), 1),
        _createElementVNode("button", {
          class: "button secondary",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('secondaryButtonClick')))
        }, _toDisplayString(_ctx.secondaryButtonText), 1)
      ])
    ])
  ]))
}