
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getAuth, signOut } from "firebase/auth";

export default defineComponent({
  components: {
  },
  setup() {
    const settingsVisible = ref(false);
    const subMenu = ref('');
    const store = useStore();
    const router = useRouter();

    const logout = () => {
      const auth = getAuth();
      signOut(auth).then(() => {
        // Sign-out successful.
        console.log('Successfully signed out')
        router.push('/login');
      }).catch((error) => {
        // An error happened.
        console.error('There was an error while signing out');
        console.error(error);
      });
    }

    return {
      logout,
      settingsVisible,
      subMenu,
      version: computed(() => store.state.shellStore.appVersion)
    }
  },
})
