
import { defineComponent, ref, reactive, computed, PropType } from "vue";

export default defineComponent({
  props: {
    password: {
      type: String as PropType<string>,
      required: true
    },
    showPasswordSuggestions: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    }
  },
  setup(props) {
    const hasAtLeast8Characters = 
      computed(() => props.password.length >= 8); 

    const hasLowerCase = 
      computed(() => testForRegExp(/(?=.*[a-z])/) );
    
    const hasUpperCase = 
      computed(() => testForRegExp(/(?=.*[A-Z])/) );
    
    const hasNumberCharacter = 
      computed(() => testForRegExp(/(?=.*\d)/) );
    
    const hasSpecialCharacter = 
      computed(() => testForRegExp(/(?=.*\W)/) );
      // computed(() => testForRegExp(/(?=.*\[-\+_!@#\$%\^&\*\.,\?])/) );


const testForRegExp = (regex: RegExp) => {
      return new RegExp(regex).test(props.password)
    }

    const percentage = computed(() => {
      let total = 0
      if (hasAtLeast8Characters.value) total += 25;
      if (hasLowerCase.value && hasUpperCase.value) total += 25;
      if (hasNumberCharacter.value) total += 25;
      if (hasSpecialCharacter.value) total += 25;
      return total;
    })


    const strength = computed(() => {
      switch (percentage.value) {
        case (100): 
          return 'strongest';
        case (75):
          return 'strong';
        case (50):
          return 'medium';
        case (25):
          return 'weak';
        default: 
          return '...';
      }
    })

    return {
      hasAtLeast8Characters,
      hasLowerCase,
      hasUpperCase,
      hasNumberCharacter,
      hasSpecialCharacter,
      percentage,
      strength,
    }
  }  
})
