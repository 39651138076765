
import { defineComponent, ref, onMounted } from 'vue'

enum LoaderType {
  none = 'none',
  looped = 'looped',
  // progress = 'progress',
  refresh = 'refresh'
}

export default defineComponent({
  props: {
    fillContainer: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const loaderType = ref(LoaderType.none);

    const reload = () => { location.reload() };

    onMounted(() => {
      setTimeout(() => {
        loaderType.value = LoaderType.looped;
      }, 1000);
 
      setTimeout(() => {
        loaderType.value = LoaderType.refresh;
      }, 10000);
    })

    return {
      loaderType,
      LoaderType,
      reload
    } 
  }
})
