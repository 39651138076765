import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Cards from '../views/Cards.vue'
import Card from '../views/_Card.vue'
import NotFound from '../views/NotFound.vue'
// import Settings from '../views/Settings.vue'
// import Account from '../views/Account.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    // meta: {
    //   auth: false
    // },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: false
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
      auth: false
    }
  },
  // {
  //   path: '/',
  //   name: 'Cards',
  //   component: Cards,
  //   // meta: {
  //   //   auth: true
  //   // }
  // },
  // {
  //   path: '/card/:card',
  //   name: 'Card',
  //   component: Card,
  //   meta: {
  //     auth: false
  //   }
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
  // {
  //   path: '/account',
  //   name: 'Account',
  //   component: Account,
  //   meta: {
  //     auth: true
  //   }
  // },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   component: Settings,
  //   meta: {
  //     auth: true
  //   }
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.isUserLoggedIn) {
    console.log('if')
    // next('/');
    next();
  } else if (
    !store.getters.isUserLoggedIn
  ) {
    console.log('else if login')
    // next('/login');
    next();
  } else {
    console.log('else')
    next();
  }
})

// router.beforeEach((to, from, next) => {
//   if (!to.meta.auth) {
//     console.log('if')
//     next();
//     // next();
//   } else if (
//     to.meta.auth &&
//     !store.getters.isUserLoggedIn
//   ) {
//     console.log('else if login')
//     next('/login');
//     // next();
//   } else if (
//     !to.meta.auth &&
//     store.getters.isUserLoggedIn
//   ) {
//     console.log('else if my-cards')
//     // next('/cards');
//     next('/');
//     // next();
//   } else {
//     console.log('else')
//     next();
//   }
// })

export default router
